import * as React from 'react'
import {graphql} from 'gatsby'
import Layout from '../layouts/layout'
import GridItem from "../components/gridItem";
import Grid from "../components/grid";

const PublishersPage = ({ data }) => {
  return (
    <Layout>
      <Grid>
        { data.allWpPage.nodes.map(node => (
          <GridItem
            key={ node.id }
            title={ node.title }
            uri={ decodeURI(node.uri.replace('/index.php', '')) } centerTitle={true} />
        ))}
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  {
    allWpPage(filter: {parentDatabaseId: {eq: 145}}) {
      nodes {
        id
        title
        uri
      }
    }
  }
`

export default PublishersPage
